<template>
    <b-form-group :label-sr-only="noLabel">
        <template #label>
            <span>{{ $t(label) }}</span>
            <span v-if="required && label" class="text-danger">*</span>
        </template>
        <b-input-group>
            <model-select
                ref="campo"
                :options="options"
                v-model="content"
                @searchchange="method"
                :isError="state == false"
                :state="state"
                :isDisabled="isDisabled"
                :placeholder="this.$t(placeholder)"
                virtual-scroll
                virtual-scroll-batch-size="20"
                maxOptions="50"
            >
            </model-select>
            <slot name="append"></slot>
        </b-input-group>
        <b-form-invalid-feedback :state="state">{{
            feedback
        }}</b-form-invalid-feedback>
        <div
            class="spinner-border text-info spinner-grow-sm loading-select"
            role="status"
            v-if="loading"
        >
            <span class="sr-only">Loading...</span>
        </div>
    </b-form-group>
</template>

<script>
import { ModelSelect } from 'vue-search-select';
import 'vue-search-select/dist/VueSearchSelect.css';

export default {
    name: 'InputSelectSearch',
    components: {
        ModelSelect,
    },
    props: {
        value: { type: [String, Number, Object, Array] },
        label: { type: String, required: false },
        required: { type: Boolean, default: false },
        loading: { type: Boolean, default: false },
        requiredMessage: { type: String },
        options: { type: Array, required: true },
        noLabel: { type: Boolean, default: false },
        method: { type: Function, default: new Function() },
        isDisabled: { type: Boolean, default: false },
        placeholder: { type: String },
        limparValidacao: { type: Boolean, default: false },
    },
    data() {
        return {
            content: this.value,
            validarFormulario: false,
            hasError: false,
            errorMessage: '',
            isField: true,
        };
    },
    computed: {
        requiredValid() {
            return !(this.required && !this.value);
        },
        state() {
            if (!this.validarFormulario) return null;

            if (!this.requiredValid) return false;
            if (this.hasError) return false;

            return true;
        },
        feedback() {
            if (!this.requiredValid)
                return (
                    this.requiredMessage ||
                    this.$t('CAMPOS_VALIDACAO.REQUERIDO').formatUnicorn({
                        name: this.label,
                    })
                );

            if (this.hasError) return this.errorMessage;
            return '';
        },
    },
    methods: {
        showError(message) {
            this.validarFormulario = true;
            this.hasError = true;
            this.errorMessage = message;
        },
        valid() {
            this.validarFormulario = true;
            return this.state;
        },
        clear() {
            this.content = '';
            this.validarFormulario = false;
            this.$emit('input', this.content);
        },
        functionDefault() {},
    },
    watch: {
        value: {
            handler(value) {
                if (value && typeof value === 'object') {
                    const matchingOption = this.options.find(
                        (option) => option.id === value.id
                    );
                    if (matchingOption) {
                        this.content = matchingOption;
                    }
                } else {
                    this.content = value;
                }
            },
            immediate: true,
            deep: true,
        },
        content(value) {
            this.$emit('input', value);
            this.hasError = false;
        },
        limparValidacao: {
            handler(value) {
                if (value) {
                    this.clear();
                }
            },
        },
    },
};
</script>

<style>
.loader {
    position: absolute;
    padding: 9px;
    z-index: 1;
}

.spinner {
    width: 18px;
    height: 18px;
}

.ui.selection.dropdown.error {
    background: #fff;
    border-color: #dc3545;
}

.ui.selection.dropdown {
    padding: 12px 12px !important;
    border-radius: 8px !important;
}

.ui.selection.dropdown > .delete.icon,
.ui.selection.dropdown > .dropdown.icon,
.ui.selection.dropdown > .search.icon {
    top: 12px !important;
}

.input-group .disabled {
    background: #e9ecef !important;
    color: #4f5467 !important;
    border-color: rgba(0, 0, 0, 0.1) !important;
    opacity: 0.8 !important;

}
</style>
