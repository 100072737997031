<template>
    <b-form-group>
        <template #label>
            <span>{{ $t(label) }}</span>
            <span v-if="required && label" class="text-danger">*</span>
        </template>
        <b-input-group
            v-bind:class="{ 'is-valid': state, 'is-invalid': state == false }"
        >
            <b-form-input
                v-model="contentText"
                type="text"
                v-mask="'##/##/####'"
                placeholder="DD/MM/AAAA"
                autocomplete="off"
                :state="state"
                :disabled="disabled"
            ></b-form-input>
            <b-form-datepicker
                v-model="content"
                :state="state"
                :disabled="disabled"
                @input="converteDataEnBr"
                button-only
                right
            />
            <b-form-invalid-feedback>{{ feedback }}</b-form-invalid-feedback>
        </b-input-group>
    </b-form-group>
</template>

<script>
import mensagem from '@/common/utils/mensagem';
export default {
    name: 'InputDateOnlyBtn',
    props: {
        value: { type: [String, Date], default: '' },
        label: { type: String, required: true },
        required: { type: Boolean },
        requiredMessage: { type: String },
        disabled: { type: Boolean, default: false },
        dataPassado: { type: Boolean, default: false },
    },
    data() {
        return {
            content: '',
            contentText: '',
            dataEditada: '',
            validarFormulario: false,
            hasError: false,
            erroData: false,
            errorMessage: 'Data inválida',
            isField: true,
        };
    },
    watch: {
        value: {
            immediate: true,
            handler(newData) {
                if (newData.length > 10) {
                    this.content = this.formatarData(newData);
                    this.converteDataEnBr(newData);
                }
                if (!newData) {
                    this.contentText = '';
                }
            },
        },
        erroData() {
            if (this.erroData == true) {
                mensagem.erro(
                    'Data Inválida!',
                    'A data selecionada deve ser anterior à data atual.'
                );
            }
        },
        contentText() {
            if (this.contentText.length == 10) {
                this.converteDataBrEn(this.contentText);
                this.emitData();
            } else {
                this.content = '';
            }
        },
        emitDate() {
            this.contentText.length != 10
                ? (this.hasError = true)
                : (this.hasError = false);
            this.emitData();
        },
    },
    computed: {
        emitDate() {
            return this.contentText;
        },
        requiredValid() {
            return !(this.required && this.value == '');
        },
        state() {
            if (!this.validarFormulario) return null;
            if (!this.requiredValid || this.erroData) return false;
            return true;
        },
        feedback() {
            if (!this.validarFormulario) return '';
            if (!this.requiredValid)
                return (
                    this.requiredMessage ||
                    this.$t('CAMPOS_VALIDACAO.REQUERIDO').formatUnicorn({
                        name: this.$t(this.label),
                    })
                );
            if (this.hasError) {
                return this.errorMessage;
            }
            return '';
        },
    },
    methods: {
        validarDataPassado() {
            if (this.dataPassado) {
                const dataSelecionada = new Date(this.content);
                const dataAtual = new Date();
                if (dataSelecionada > dataAtual) {
                    this.hasError = true;
                    this.erroData = true;
                } else {
                    this.hasError = false;
                    this.erroData = false;
                }
            }
        },
        converteDataBrEn(dataBR) {
            let dataAmericana =
                dataBR[6] +
                dataBR[7] +
                dataBR[8] +
                dataBR[9] +
                '-' +
                dataBR[3] +
                dataBR[4] +
                '-' +
                dataBR[0] +
                dataBR[1];
            let novaData = `${dataAmericana}T00:00`;
            this.content = novaData;
            return;
        },
        converteDataEnBr() {
            let dataCalendario = new Date(`${this.content}T00:00`);
            let newDate = new Intl.DateTimeFormat('pt-BR').format(
                dataCalendario
            );
            this.dataEditada = newDate;
            this.contentText = this.dataEditada;
            return;
        },
        alteraFormatoDataDB(dataVindoDaDB) {
            // a data na DB é salvada no formato americano, então para converter:
            let calendarDate = new Date(dataVindoDaDB);
            let newDate = new Intl.DateTimeFormat('pt-BR').format(calendarDate);
            this.dataEditada = newDate;
            this.contentText = this.dataEditada;
            return;
        },
        formatarData(data) {
            return data ? data.substring(0, 10) : '';
        },
        formatarDataTexto(data) {
            if (data) {
                const [ano, mes, dia] = data.substring(0, 10).split('-');
                return `${dia}/${mes}/${ano}`;
            }
            return '';
        },
        valid() {
            this.validarFormulario = true;
            return this.state;
        },
        clear() {
            this.content = '';
            this.contentTime = '';
            this.validarFormulario = false;
            this.$emit('input', this.contentTime);
        },
        emitData() {
            this.validarDataPassado();
            this.$emit('input', this.content);
            this.$emit('change', this.content);
        },
    },
};
</script>
<style>
.is-valid button {
    border-color: #7dba19;
}
.is-invalid button {
    border-color: #dc3545;
}

.input-group {
    height: 40px;
}
</style>
